<template>
  <div class="modal-container" @click="handleClose">
    <div class="modal-content" @click.stop="">
      <h2>Selecione as opções de exportação do relatório</h2>
      <div class="wrapper-container">
        <h3>Período do relatório</h3>
        <el-date-picker
          :class="hasErrorDate ? 'picker-error' : 'picker'"
          v-model="date"
          type="monthrange"
          range-separator="até"
          start-placeholder="Mês inicial"
          end-placeholder="Mês final"
          format="MM/yyyy"
          :clearable="false"
          :picker-options="datePickerOptions"
          @change="() => (hasErrorDate = false)"
        >
          >
        </el-date-picker>
        <el-alert
          class="alert"
          :title="messageError"
          type="error"
          v-if="hasErrorDate"
          @close="handleCloseAlert"
        />
      </div>
      <div class="wrapper-container">
        <h3>Opção de exportação do relatório</h3>
        <el-radio v-model="radio" label="false">Planilhas individuais</el-radio>
        <el-radio v-model="radio" label="true" :disabled="hasMinLength">
          Planilha consolidada
        </el-radio>
        <el-checkbox class="lgpd-check__container" v-model="isAnonymous" border>
          <span class="lgpd-check__text">
            LGPD (Não exibir os dados dos colaboradores no relatório).
          </span>
        </el-checkbox>
      </div>
      <div class="button-container">
        <el-button class="button-secondary" @click="handleClose">
          Cancelar
        </el-button>
        <el-button class="button-primary" @click="handleSave">
          Exportar
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from '@/utils/dayjs'

export default {
  name: 'Modal',
  data() {
    return {
      radio: 'false',
      date: [],
      minDate: '',
      hasErrorDate: false,
      messageError: '',
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date()
        },
      },
      isAnonymous: false,
    }
  },
  props: {
    currentDate: Date,
    projectsLength: Number,
  },
  methods: {
    ...mapGetters(['getSelectedMonth']),

    handleCloseAlert() {
      this.hasErrorDate = false
    },
    handleSave() {
      const [init, end] = this.date
      let hasError = false
      if (hasError) {
        hasError = init.getTime() === end.getTime() ? false : true
      }
      if (hasError) {
        this.hasErrorDate = true
      } else {
        this.$emit('download', {
          date: this.date,
          union: this.radio === 'true' ? true : false,
          isAnonymous: this.isAnonymous,
        })
      }
    },
    handleClose() {
      this.$emit('close')
    },
    disabledDate(newDate) {
      if (this.date) {
        return newDate === this.currentDate
      }
      return false
    },
    pick({ maxDate, minDate }) {
      this.date = minDate
    },
  },
  computed: {
    hasMinLength() {
      return this.projectsLength === 1
    },
  },
  created() {
    const date = this.getSelectedMonth()
    this.date = [date, date]
    this.minDate = `${this.currentDate.getFullYear()}-${
      this.currentDate.getMonth() + 1
    }`
    this.messageError =
      'O período selecionado não possui informações cadastradas' ||
      `O período selecionado deve conter o seguinte mês: ${dayjs(
        this.minDate
      ).format('MM/YYYY')}`
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
