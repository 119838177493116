<template>
  <el-main id="export-page">
    <div class="header-container">
      <div class="wrapper-info">
        <h3>Projetos</h3>
        <el-date-picker
          v-model="date"
          type="month"
          placeholder="Selecione um mês"
          format="MM/yyyy"
          :clearable="false"
          :picker-options="datePickerOptions"
          @change="newDatePeriod"
        >
        </el-date-picker>
        <span>{{ selectedProjectsSize }} projetos selecionados</span>
      </div>
      <div class="search-wrapper">
        <el-select
          v-model="projectsFilter"
          id="projects-filter"
          @change="handleFilteredProjects"
        >
          <el-option
            v-for="item in filteredProjectsOptions"
            :key="`locale-project-${item.label}-${item.value}`"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="localeFilter"
          id="locale-filter"
          @change="handleLocale"
        >
          <el-option
            v-for="item in localeOptions"
            :key="`export-locale-${item.label}-${item.value}`"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          id="search-field"
          class="search-input"
          v-model="search"
          size="medium"
          placeholder="Pesquisar na tabela"
          clearable
          prefix-icon="el-icon-search"
        ></el-input>
        <el-button
          id="export-button"
          class="primary-button"
          @click="handleExport"
          :disabled="isBtnDownload"
        >
          Exportar
        </el-button>
      </div>
    </div>
    <section class="table-container">
      <el-table
        size="small"
        :data="getPageContent"
        height="100%"
        max-height="100%"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
        v-loading="loading"
        class="table-container__inner"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="C. Custo"
          prop="costCenterCode"
          header-align="center"
          align="center"
          sortable
        />
        <el-table-column
          label="Nome"
          prop="projectName"
          show-overflow-tooltip
          :formatter="handleTableFormatData"
          sortable
        />
        <el-table-column
          label="Mês início"
          prop="startDate"
          show-overflow-tooltip
          :formatter="handleFormatTableDate"
          header-align="center"
          align="center"
          sortable
        />
        <el-table-column
          label="Mês fim"
          prop="endDate"
          show-overflow-tooltip
          :formatter="handleFormatTableDate"
          header-align="center"
          align="center"
          sortable
        />
        <el-table-column
          label="Gerente"
          prop="manager"
          show-overflow-tooltip
          sortable
        />
        <el-table-column
          label="Planilha GP"
          prop="hasManagerSheet"
          :formatter="handleFormatIcon"
          align="center"
        />
        <el-table-column label="Cost tracking" align="center">
          <template slot-scope="scope">
            <el-button
              @click="toCostTracking(scope.row)"
              class="button-cost-tracking"
              :disabled="!hasCostTracking(scope.row)"
            >
              <span>Ver Cost tracking</span>
              <i class="el-icon-document" />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <Modal
      v-if="isModal"
      @close="handleCloseModal"
      @download="handleDonwload"
      :currentDate="date"
      :projectsLength="selectedProjectsSize"
    />
    <ModalConfirm
      :show="isModalConfirm"
      :projects="projectsWithoutImports"
      @close="handleCloseConfirm"
      @cancel="handleCloseConfirm"
      @confirm="handleConfirm"
    />
  </el-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getMonthDetails } from '@/service/monthlyDetails'
import { downloadFinalSheets } from '@/service/export'
import { getLocations } from '@/service/locations'
import Modal from './Partials/Modal/Modal.vue'
import ModalConfirm from './Partials/ModalConfirm/ModalConfirm.vue'
import dayjs from '@/utils/dayjs'

export default {
  name: 'ExportPage',
  data() {
    return {
      date: '',
      search: '',
      selectedProjectsSize: 0,
      selectedProjects: [],
      contentData: [],
      contentBuffer: [],
      loading: false,
      startDate: '',
      endDate: '',
      isBtnDownload: true,
      isModal: false,
      isModalConfirm: false,
      projectsWithoutImports: [],
      localeFilter: '0',
      localeOptions: [
        {
          value: '0',
          label: 'Todas as Filiais',
        },
      ],
      searchColumns: [
        { propName: 'manager', propType: 'String' },
        { propName: 'projectName', propType: 'String' },
        { propName: 'costCenterCode', propType: 'String' },
        { propName: 'endDate', propType: 'Date' },
        { propName: 'startDate', propType: 'Date' },
      ],
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date()
        },
      },
      filteredProjectsOptions: [
        {
          value: 'encouraged',
          label: 'Projetos incentivados',
        },
        {
          value: 'all',
          label: 'Todos os projetos',
        },
      ],
      projectsFilter: 'Projetos incentivados',
      filteredOption: 'encouraged',
    }
  },
  components: {
    Modal,
    ModalConfirm,
  },
  methods: {
    ...mapGetters(['getSelectedMonth']),
    ...mapActions(['updateSelectedMonth']),
    async handleFilteredProjects(option) {
      this.filteredOption = option
      await this.handleMonthDetails()
      this.handleLocale()
    },
    handleExport() {
      const projectsWithoutImports = this.selectedProjects.filter(
        ({ item }) => !item.hasManagerSheet
      )

      this.projectsWithoutImports = projectsWithoutImports

      if (projectsWithoutImports?.length > 0) {
        this.handleOpenConfirm()
      } else {
        this.handleOpenModal()
      }
    },
    handleConfirm() {
      this.isModalConfirm = false
      this.handleOpenModal()
    },
    handleCloseConfirm() {
      this.isModalConfirm = false
    },
    handleOpenConfirm() {
      this.isModalConfirm = true
    },
    handleOpenModal() {
      this.isModal = true
    },
    handleCloseModal() {
      this.isModal = false
    },
    handleSelectedChange() {
      this.selectedProjectsSize = this.selectedProjects.length
    },
    handleSelectionChange(val) {
      this.selectedProjects = val.map((element) => {
        return {
          item: element,
        }
      })
    },
    toCostTracking({ generalInfoId, financialReportId }) {
      this.$router.push({
        name: 'CostTrackingPage',
        params: {
          id: generalInfoId,
          frId: financialReportId,
          tabName: 'equip',
        },
      })
    },
    hasCostTracking({ hasManagerSheet }) {
      return hasManagerSheet
    },
    handleLocale() {
      const filter = this.localeFilter
      if (filter && filter !== '0') {
        let newData = this.contentData.filter((item) => {
          let searchValue = filter.toLowerCase()
          const hasOnColumn = String(item.location)
            .toLowerCase()
            .includes(searchValue)
          if (hasOnColumn) {
            return item
          }
        })
        this.contentBuffer = newData
      } else {
        this.contentBuffer = this.contentData
      }
      this.search = ''
    },
    handleFormatTableDate(row, column, cellValue, index) {
      return this.handleMonthAndYearFormatter(cellValue)
    },
    handleTableFormatData(row, column, cellValue, index) {
      return this.handleFormatData(cellValue)
    },
    handleFormatIcon(row, column, cellValue, index) {
      return cellValue ? (
        <i class="el-icon-success icon-sucess" />
      ) : (
        <i class="el-icon-warning icon-warning" />
      )
    },
    formatDate(date) {
      return dayjs(date).locale('pt-br').format('MMM/YYYY')
    },
    formatFileName(startDate, endDate, union, projects = []) {
      let exportFileName = 'ANEXO_VIII_'
      let fileExtension = '.xlsx'

      if (projects.length > 1 && !union) {
        fileExtension = '.zip'
        exportFileName = 'ANEXOS_'
      } else {
        exportFileName = projects.reduce(
          (acc, curr) => (acc += `${curr.projectName}_`),
          exportFileName
        )
      }

      return exportFileName.concat(
        dayjs(startDate).startOf('month').toISOString() ===
          dayjs(endDate).startOf('month').toISOString()
          ? `${this.formatDate(startDate)}${fileExtension}`
          : `${this.formatDate(endDate)}${fileExtension}`
      )
    },
    async handleDonwload({ date, union, isAnonymous }) {
      this.startDate = date[0]
      this.endDate = date[1]
      const reqs = this.selectedProjects.map(({ item }) => item)
      const loading = this.$loading({
        lock: true,
        text: 'Seu download começará em alguns instantes.',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const costCenterIds = reqs.map((item) => item.costCenterId)
        await this.download(
          costCenterIds,
          this.formatFileName(this.startDate, this.endDate, union, reqs),
          union,
          isAnonymous
        )
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response?.data || error.message,
          duration: 5000,
        })
      } finally {
        loading.close()
        this.isModal = false
      }
    },
    async download(costCenterIds, exportName, union, isAnonymous) {
      const start = this.startDate
      const end = this.endDate
      try {
        const { data } = await downloadFinalSheets(
          start,
          end,
          costCenterIds,
          union,
          isAnonymous
        )
        if (data) {
          let FILE = window.URL.createObjectURL(new Blob([data]))
          let docUrl = document.createElement('a')
          docUrl.href = FILE
          docUrl.setAttribute('download', exportName.trim())
          document.body.appendChild(docUrl)
          docUrl.click()
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: JSON.parse(await error.response.data.text()),
          duration: 5000,
        })
      }
    },
    async handleMonthDetails() {
      try {
        this.loading = true
        const { data, status } = await getMonthDetails(
          this.date,
          this.filteredOption
        )
        if (status === 204) {
          this.contentData = []
        } else {
          this.contentData = data
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
        this.contentData = []
      } finally {
        this.loading = false
        this.contentBuffer = this.contentData
        this.selectedProjects = []
      }
    },
    async newDatePeriod(newDate) {
      this.date = newDate
      this.updateSelectedMonth(newDate)
      await this.handleMonthDetails()
    },
    async getLocales() {
      try {
        const { data } = await getLocations()
        if (data) {
          const newOption = data.map((element) => {
            return {
              value: element,
              label: element,
            }
          })
          this.localeOptions = [...this.localeOptions, ...newOption]
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
  },
  computed: {
    getPageContent() {
      const pageContent = this.handlePageContent(
        this.search,
        this.searchColumns,
        this.contentBuffer
      )
      return pageContent
    },
  },
  watch: {
    selectedProjects() {
      this.handleSelectedChange()
      this.isBtnDownload = this.selectedProjectsSize === 0 ? true : false
    },
  },
  async created() {
    this.date = this.getSelectedMonth()
    await this.handleMonthDetails()
    await this.getLocales()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
