import api from '../instance'

export function downloadFinalSheets(
  startDate,
  endDate,
  costCenterIds,
  union,
  isAnonymous
) {
  let data = { startDate, endDate, costCenterIds, union, isAnonymous }
  return api.post('/export', data, {
    responseType: 'blob',
  })
}
