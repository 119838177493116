<template>
  <div v-if="show" class="modal-container" @click="handleClose">
    <div class="modal-content" @click.stop="">
      <h2>
        Projetos selecionados para exportação não possuem planilha de gerente
        importada
      </h2>
      <div class="wrapper-container">
        <span>
          Ao exportar um ou mais projetos, onde um destes não possui a planilha
          de gerente importada, podem haver problemas ou inconsistências de
          formatação na planilha exportada. deseja continuar com a ação de
          exportação?
        </span>
      </div>
      <div class="wrapper-container">
        <span>Projetos sem a importação de planilha do gerente:</span>
        <span>{{ projectsCostCenterCode }}</span>
      </div>
      <div class="button-container">
        <el-button class="button-secondary" @click="handleCancel">
          Cancelar
        </el-button>
        <el-button class="button-primary" @click="handleConfirm">
          Continuar com a exportação
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirm',
  data() {
    return {
      projectsCostCenterCode: '',
    }
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm')
    },
    handleClose() {
      this.$emit('close')
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleFormatProjects(projects) {
      this.projectsCostCenterCode = projects
        ?.map(({ item }) => item?.costCenterCode)
        .join()
    },
  },
  watch: {
    projects: {
      handler(value) {
        this.handleFormatProjects(value)
      },
    },
  },
  computed: {},
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
